.App {
  text-align: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(6px + 2vmin);
  font-family: "Work Sans", sans-serif;
  font-weight: 600;
  width: 100%;
  }

.App-header a, .App-header a:visited, .App-header a:hover {
  color: #0D2963;
}

.App-header .pnecImg {
 width: 120px; 
}

@media (max-width: 600px) {
  .App-header {
    flex-direction: column;
  }
  
}

.social-networks {
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-content: flex-end;
}

.social-networks a {
  display: flex;
}

.social-networks img {
  /* width: 100%; */
  height: 30px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

footer {
  height: 50px;
}

footer img{
  padding: 0 10px;
  height: 84px;
}