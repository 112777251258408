a {
  color: white;
  text-decoration: none;
}

/* Unvisited link  */
a:visited {
  color:white;
}

/* Visited link    */
a:hover {
  color: white;
}

/* Mouse over link */
a:active {
  color: white;
}

.documents {
  display: flex;
  flex-direction: row;
  min-height: 395px;
  padding-top: 50px;
  color: white;
  font-family: 'Work Sans', sans-serif;
  padding: 50px 10%;
}

.part1 {
  height: 100px;
  flex: 1;
  font-size: 45px;
  font-weight: 500;
}

.part2 {
  display: flex;
  flex-direction: column;
  flex: 2 1;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 27px;
}

.part2 a {
  width: 60%;
  display: flex;
  align-items: center;
}

.file {
  border-bottom: 1px solid #174FD4;
}

@media (max-width: 600px) {
  .documents {
    flex-direction: column;
  }
  
}
