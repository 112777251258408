.banner {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}


@media (max-width: 600px) {
    .banner {
    width: 100%;
    height: 550px;
        object-fit: cover;
    }
}