body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cronograma {
  display: flex;
  background-color: #1C2633; 
  padding: 2% 20% 5%;
  flex-direction: column;
  text-align: left;
  /* min-height: 623px; */
}

.cronograma .title {
  color: #63D9C3;
  font-weight: bold;
  font-size: 27px;
}

.cronograma .dates {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #63D9C3;
  color: white;
}

.cronograma .dates p {
  margin-bottom: 0;
  font-size: calc(6px + 2vmin);
}

.cronograma .dates div {
  width: 50%;
  margin-bottom: 0;
  padding-right: 15px;

}

@media (max-width: 725px) {
  .cronograma{
    padding: 0 30px 30px;

  }
}


