h4 {
  margin: 0%;
  font-size: calc(8px + 2vmin);
}

.convocatorias {
  display: flex;
  /* min-height: 100vh; */
  font-family: "Work Sans", sans-serif;
  /* padding: 36px 0% 0px; */
  flex-direction: column;
}

.seaflower {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.firstDiv img {
    max-height: 600px;
}

.secondDiv {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 18px;
  padding: 0 30px;
  width: 40%;
}

.button {
  background-color: #5a9daf;
  color: white;
  text-align: center;
  padding: 15px 40px;
  width: 83%;
  border: 0px;
  font-size: 20px;
  font-weight: bold;
}

.parrafo {
  color: gray;
  text-align: justify;
  font-size: calc(7px + 2vmin);
}

@media (max-width: 1024px) {
  .convocatorias {
    padding: 36px 0%;
  }
  .seaflower {
    flex-direction: row;
    justify-content: center;
  }
  .secondDiv {
    padding: 0 20px;
    font-size: 14px;
    align-items: center;
  }
  .firstDiv img {
    max-height: 400px;
  }
}

@media (max-width: 768px) {
  .seaflower {
    flex-direction: column;
    align-items: center;
  }
  .firstDiv img {
    height: 300px;
    width: unset;
  }
  .secondDiv {
    padding: 20px;
    width: 80%;
  }
}
